import { useCallback } from 'react';

import { DateArg, formatDate } from '@sbiz/util-dates';

import { useLang } from './useLang';

export function useFormatDate() {
  const [lang] = useLang();

  return useCallback((date: DateArg | null | undefined, format = 'PP') => formatDate(date, format, lang), [lang]);
}
