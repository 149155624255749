import {
  FormHelperTextProps,
  InputAdornment,
  InputBaseProps,
  InputLabelProps,
  InputProps,
  MenuItem,
  MenuItemProps,
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
  SelectProps,
  TextFieldVariants,
} from '@mui/material';
import { ButtonHTMLAttributes, ReactNode, useMemo } from 'react';

import { CURRENCY, Lang } from '@sbiz/common';

import { styledProps } from '../../common/styles';

export const HELPER_TEXT_HEIGHT = 32;

const NUMBER_TYPES = ['amount', 'number'] as const;
type NumberType = (typeof NUMBER_TYPES)[number];

export type HtmlInputProps = { max?: number; maxLength?: number; min?: number; pattern?: RegExp; step?: number };

export type SelectOptionObject<TValue = string> = {
  label: ReactNode;
  menuItemProps?: MenuItemProps;
  renderValue?: ReactNode;
  value: TValue;
};

export type SelectOption<TValue = string> = SelectOptionObject<TValue> | (() => ReactNode);

export type TextFieldProps<T extends TextFieldVariants = TextFieldVariants, TValue = string | symbol> = {
  htmlInputProps?: HtmlInputProps;
} & {
  slotProps?: {
    htmlInput?: InputBaseProps['inputProps'];
    input?: InputProps;
    inputLabel?: InputLabelProps;
    formHelperText?: FormHelperTextProps;
    select?: SelectProps;
  };
} & (
    | {
        multiple?: boolean;
        options: SelectOption<TValue extends unknown[] ? TValue[number] : TValue>[];
        select: true;
      }
    | { multiple?: never; options?: never; select?: false | undefined }
  ) &
  Omit<MuiTextFieldProps<T>, 'select' | 'slotProps'>;

const LANG_OPTIONS: SelectOption<Lang>[] = [
  { label: 'FR', value: 'fr' },
  { label: 'DE', value: 'de' },
  { label: 'IT', value: 'it' },
  { label: 'EN', value: 'en' },
];

export function TextField<T extends TextFieldVariants>({
  multiple,
  options: propsOptions,
  ...props
}: TextFieldProps<T>) {
  const options = useMemo(() => {
    if (propsOptions) {
      return propsOptions;
    }

    if (props?.type === 'lang-select') {
      return LANG_OPTIONS;
    }
  }, [props?.type, propsOptions]);

  const textFieldProps = useMemo(() => {
    const { htmlInputProps, ...textFieldProps } = props;
    const { onBlur, onChange, select, slotProps, type } = textFieldProps;

    textFieldProps.slotProps = {
      ...slotProps,
      formHelperText: styledProps({ display: 'flex', minHeight: HELPER_TEXT_HEIGHT }, slotProps?.formHelperText),
      input: styledProps(
        { backgroundColor: slotProps?.input?.readOnly ? 'action.disabledBackground' : 'white' },
        slotProps?.input,
      ),
    };

    if (select || type?.endsWith('-select')) {
      textFieldProps.select = true;
      textFieldProps.slotProps.select = { ...slotProps?.select, MenuProps: { sx: { maxHeight: 300 } }, multiple };
    }

    if (!multiple) {
      textFieldProps.onBlur = (event) => {
        const { value } = event.target;

        let formattedValue = value.trim();

        if (NUMBER_TYPES.includes(type as NumberType)) {
          formattedValue = getFormattedNumber(formattedValue, htmlInputProps?.step);
        }

        if (formattedValue !== value) {
          event.target.value = formattedValue;
          onChange?.(event);
        }

        onBlur?.(event);
      };

      if (NUMBER_TYPES.includes(type as NumberType)) {
        if (type === 'amount') {
          textFieldProps.slotProps.input = {
            ...textFieldProps.slotProps.input,
            startAdornment: (
              <>
                <InputAdornment position="start">{CURRENCY}</InputAdornment>
                {slotProps?.input?.startAdornment}
              </>
            ),
          };
        }

        textFieldProps.type = 'text';
        textFieldProps.slotProps.htmlInput = { inputMode: 'decimal', ...slotProps?.htmlInput };

        textFieldProps.onChange = (event) => {
          const { value } = event.target;

          if (value && !isValidNumber(value, htmlInputProps?.max)) {
            event.preventDefault();
          } else {
            if (htmlInputProps?.step && value && /\d+[.,]\d{2}$/.test(value)) {
              event.target.value = getFormattedNumber(value, htmlInputProps.step);
            }

            onChange?.(event);
          }
        };
      } else if (htmlInputProps) {
        const { maxLength, pattern } = htmlInputProps;

        if (maxLength || pattern) {
          textFieldProps.onChange = (event) => {
            const { value } = event.target;

            const isInvalidLength = value && maxLength && value.length > maxLength;
            const isInvalidPattern = value && pattern && !pattern.test(value);

            if (isInvalidLength || isInvalidPattern) {
              event.preventDefault();
            } else {
              onChange?.(event);
            }
          };
        }
      }
    }

    return textFieldProps;
  }, [multiple, props]);

  return (
    <MuiTextField {...textFieldProps}>
      {textFieldProps?.select &&
        options?.map((option, index) => {
          if (typeof option === 'function') {
            const Option = option;
            return <Option key={index} />;
          }

          const { label, menuItemProps, value } = option;
          return (
            <MenuItem key={index} value={value as ButtonHTMLAttributes<unknown>['value']} {...menuItemProps}>
              {label}
            </MenuItem>
          );
        })}
    </MuiTextField>
  );
}

export function getFormattedNumber(value: number | string, step?: number) {
  if (value) {
    const number = typeof value === 'string' ? parseFloat(getFormattedSeparator(value)) : value;

    if (step && step !== 0.01) {
      const digitCount = step % 1 === 0 ? 0 : 2;
      return (Math.round(number / step) * step).toFixed(digitCount);
    }

    return number.toFixed(2);
  }

  return '';
}

function getFormattedSeparator(numberValue: string) {
  return numberValue.replace(',', '.');
}

function isValidNumber(value: string, max?: number) {
  const isValidString = /^\d+(?:[.,]\d{0,2})?$/.test(value);

  if (isValidString) {
    const number = parseFloat(getFormattedSeparator(value));
    return number <= (max ?? 1_000_000);
  }

  return false;
}
