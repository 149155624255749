import { Link as MuiLink, LinkProps as MuiLinkProps } from '@mui/material';
import NextLink, { LinkProps as NextLinkProps } from 'next/link';
import { forwardRef, MouseEvent, useCallback, useMemo } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { styledProps } from '../../common/styles';

export type LinkProps = { disabled?: boolean; isLoading?: boolean } & Omit<MuiLinkProps, 'component'>;

export const Link = forwardRef<HTMLAnchorElement, LinkProps>(function Link(
  { disabled, href, isLoading, onClick, ...props },
  ref,
) {
  const handleClick = useCallback(
    (event: MouseEvent<HTMLAnchorElement>) => {
      if (onClick) {
        event.preventDefault();

        if (!disabled && !isLoading) {
          onClick(event);
        }
      }
    },
    [disabled, isLoading, onClick],
  );

  const debouncedHandleClick = useDebouncedCallback(handleClick, 500, { leading: true, trailing: false });

  const linkProps = useMemo(() => {
    const rel = props?.target === '_blank' ? 'noreferrer' : undefined;
    const linkProps = { rel, ...props };

    if (disabled || isLoading) {
      return styledProps(
        { color: 'inherit', cursor: isLoading ? 'progress' : 'not-allowed', opacity: '70%', textDecoration: 'none' },
        linkProps,
      );
    }

    return linkProps;
  }, [disabled, isLoading, props]);

  return (
    <MuiLink component={LinkComponent} href={href ?? '#'} onClick={debouncedHandleClick} ref={ref} {...linkProps} />
  );
});

const LinkComponent = forwardRef<HTMLAnchorElement, NextLinkProps>(function LinkComponent({ prefetch, ...props }, ref) {
  return <NextLink prefetch={prefetch ?? false} ref={ref} {...props} />;
});
