import { forwardRef, ReactNode } from 'react';

import { Span } from './Span';

const STYLES = { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' };

export const TableCellText = forwardRef<HTMLSpanElement, { children: ReactNode }>(function TableCellText(props, ref) {
  return (
    <Span ref={ref} sx={STYLES} title={typeof props.children === 'string' ? props.children : undefined} {...props} />
  );
});
